import { Injectable, EventEmitter } from '@angular/core';
import { AppAuthService } from '../auth/app-auth.service';
import { SessionServiceProxy } from '@shared/service-proxies/service-proxies';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionTimeoutService {
    public sessionTimeoutEvent: EventEmitter<void> = new EventEmitter<void>();
    private readonly isDisableSessionTimeoutSource = new Subject<boolean>();
    isDisableSessionTimeoutSource$ = this.isDisableSessionTimeoutSource.asObservable();


    constructor(
        private _appAuthService: AppAuthService,
        private _sessionService: SessionServiceProxy
    ) {
        // Listen for the localStorage event to handle session timeout across all tabs
        window.addEventListener('storage', this.onSessionTimeoutEvent.bind(this));
    }

    disableSessionTimeout(): void {
        setTimeout(() => this.isDisableSessionTimeoutSource.next(true));
    }

    enableSessionTimeout(): void {
        setTimeout(() => this.isDisableSessionTimeoutSource.next(false));
    }

    emitSessionTimeout() {
        this.sessionTimeoutEvent.emit();
        localStorage.setItem('session-timeout-event', JSON.stringify({
            timestamp: new Date().getTime()
        }));

        const isSessionLockScreenEnabled = abp.setting.getBoolean('App.UserManagement.SessionTimeOut.ShowLockScreenWhenTimedOut');
        if (isSessionLockScreenEnabled) {
            this.redirectToLockScreen();
        } else {
            this._appAuthService.logout(true);
        }
    }

    private onSessionTimeoutEvent(event: StorageEvent) {
        if (event.key === 'session-timeout-event') {
            window.location.href = '/account/session-locked';
        }
    }

    private redirectToLockScreen(): void {
        this._sessionService.getCurrentLoginInformation().subscribe({
            next: (info) => {
                if (info) {
                    abp.utils.setCookieValue('userInfo', JSON.stringify({
                        userName: info.user.userName,
                        profilePictureId: info.user.profilePictureId,
                        tenant: info.tenant ? info.tenant.tenancyName : 'Host'
                    }), null, abp.appPath);
                    this._appAuthService.logout(true, '/account/session-locked');
                } else {
                    this._appAuthService.logout(true);
                }
            },
            error: () => {
                this._appAuthService.logout(true);
            }
        });
    }
}
