import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppConsts } from '@shared/AppConsts';
import { LocalStorageService } from '@shared/utils/services/local-storage.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SignalRClientService {
    private readonly retryDelays = [0, 2000, 5000, 10000, 30000, 60000, 90000, 120000];
    protected connection: HubConnection;

    constructor(private url: string) {}

    protected connect(): Observable<HubConnection> {
        return this.getEncryptedAuthToken().pipe(
            map((encryptedAuthToken) => this.createHubConnection(encryptedAuthToken)),
            tap((connection) => {
                this.connection = connection;
                this.configureConnection(connection);
            })
        );
    }

    protected disconnect(): void {
        this.connection?.stop();
    }

    private createHubConnection(encryptedAuthToken: string): HubConnection {
        const connection = new HubConnectionBuilder()
            .withUrl(this.createSignalRUrl(encryptedAuthToken))
            .withAutomaticReconnect(this.retryDelays)
            .build();
        connection
            .start()
            .then(() => console.log(`SignalR connected to ${this.url}`))
            .catch(() => console.error(`SignalR failed to connect to ${this.url}`));
        return connection;
    }

    private configureConnection(connection: HubConnection): void {
        connection.onclose((error) => {
            if (error) {
                console.log(`SignalR connection ${this.url} closed with error: ${error}`);
            } else {
                console.log(`SignalR connection closed from ${this.url}`);
            }
        });

        connection.onreconnecting(() => {
            console.log(`SignalR reconnecting to ${this.url}`);
        });

        connection.onreconnected(() => {
            console.log(`SignalR reconnected to ${this.url}`);
        });
    }

    private getEncryptedAuthToken(): Observable<string> {
        return new Observable<string>((observer) => {
            new LocalStorageService().getItem(AppConsts.authorization.encrptedAuthTokenName, function (_error: unknown, value: { token: string }) {
                observer.next(value?.token);
            });
        });
    }

    private createSignalRUrl(encryptedAuthToken: string): string {
        return (
            AppConsts.remoteServiceBaseUrl +
            this.url +
            '?' +
            AppConsts.authorization.encrptedAuthTokenName +
            '=' +
            encodeURIComponent(encryptedAuthToken)
        );
    }
}
