import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'projectDuration',
})
export class ProjectDurationPipe implements PipeTransform {
    transform(value: number): string {
        const secNow = value / 10000000;
        const d = Math.floor(secNow / 86400);
        const h = Math.floor((secNow % 86400) / 3600);
        const m = Math.floor((secNow % 3600) / 60);

        let duration = '';

        if (d > 0) duration = `${d}d`;

        if (h > 0) duration += ` ${h}h`;

        if (m > 0) duration += ` ${m}m`;

        return duration;
    }
}
