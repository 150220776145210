import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangePasswordInput, PasswordComplexitySetting, ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent extends AppComponentBase {
    @ViewChild('currentPasswordInput', {static: true}) currentPasswordInput: ElementRef;
    @ViewChild('changePasswordModal', {static: true}) modal: ModalDirective;

    passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();
    currentPassword: string;
    password: string;
    confirmPassword: string;

    saving = false;
    active = false;
    isShowCurrentPassword = false;
    isShowNewPassword = false;
    isShowNewPasswordRepeat = false;

    constructor(
        injector: Injector,
        private profileService: ProfileServiceProxy
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this.currentPassword = '';
        this.password = '';
        this.confirmPassword = '';
        this.isShowCurrentPassword = false;
        this.isShowNewPassword = false;
        this.isShowNewPasswordRepeat = false;

        this.profileService.getPasswordComplexitySetting().subscribe(result => {
            this.passwordComplexitySetting = result.setting;
            this.modal.show();
        });
    }

    onShown(): void {
        document.getElementById('CurrentPassword').focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        const input = new ChangePasswordInput();
        input.currentPassword = this.currentPassword;
        input.newPassword = this.password;

        this.saving = true;
        this.profileService.changePassword(input)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.message.success(this.l('YourPasswordHasChangedSuccessfully'));
                this.close();
            });
    }

    toggleCurrentPasswordShow(): void {
        this.isShowCurrentPassword = !this.isShowCurrentPassword;
    }

    toggleNewPasswordShow(): void {
        this.isShowNewPassword = !this.isShowNewPassword;
    }

    toggleNewPasswordRepeatShow(): void {
        this.isShowNewPasswordRepeat = !this.isShowNewPasswordRepeat;
    }
}
