import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMessage'
})
export class FormatMessagePipe implements PipeTransform {

    transform(message: string, values: unknown[]): string {
        return message.replace(/{(\d+)}/g, (_, index) => values[index].toString() || `{${index}}`);
    }

}
