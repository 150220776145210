import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'timeToString'
})
export class TimeToStringPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        const parts = value.split(':');
        const daysHours =  parts[0].split('.');
        let days = 0;
        let hours = 0;
        if (daysHours.length > 1) {
            days = parseInt(daysHours[0]);
            hours = parseInt(daysHours[1]);
        }
        else {
            hours = parseInt(daysHours[0]);
        }
        const minutes = parseInt(parts[1], 10);
        const seconds = Math.floor(parseFloat(parts[2]));
        let formattedTime = '';
        if (days < -1) return '';
        if (days > 0) {
            formattedTime += `${days}d `;
        }
        if (hours > 0 || days > 0) {
            formattedTime += `${hours}h `;
        }
        if (minutes > 0 || hours > 0 || days > 0) {
            formattedTime += `${minutes}m `;
        }
        formattedTime += `${seconds}s`;
        return formattedTime.trim();
    }
}
