export class PermissionsConsts {
    static readonly Pages = 'Pages';
    static readonly Pages_RolesAndUsers = 'Pages.RolesAndUsers';

    static readonly Pages_RolesAndUsers_Roles = 'Pages.RolesAndUsers.Roles';
    static readonly Pages_RolesAndUsers_Roles_Create = 'Pages.RolesAndUsers.Roles.Create';
    static readonly Pages_RolesAndUsers_Roles_Edit = 'Pages.RolesAndUsers.Roles.Edit';
    static readonly Pages_RolesAndUsers_Roles_Delete = 'Pages.RolesAndUsers.Roles.Delete';

    static readonly Pages_RolesAndUsers_Users = 'Pages.RolesAndUsers.Users';
    static readonly Pages_RolesAndUsers_Users_Create = 'Pages.RolesAndUsers.Users.Create';
    static readonly Pages_RolesAndUsers_Users_Edit = 'Pages.RolesAndUsers.Users.Edit';
    static readonly Pages_RolesAndUsers_Users_Delete = 'Pages.RolesAndUsers.Users.Delete';
    static readonly Pages_RolesAndUsers_Users_Block = 'Pages.RolesAndUsers.Users.Block';

    static readonly Pages_RolesAndUsers_ProjectMembers = 'Pages.RolesAndUsers.ProjectMembers';
    static readonly Pages_RolesAndUsers_ProjectMembers_Manage = 'Pages.RolesAndUsers.ProjectMembers.Manage';

    static readonly Pages_UniversalSettings = 'Pages.UniversalSettings';

    static readonly Pages_UniversalSettings_Connections = 'Pages.UniversalSettings.Connections';
    static readonly Pages_UniversalSettings_Connections_Create = 'Pages.UniversalSettings.Connections.Create';
    static readonly Pages_UniversalSettings_Connections_Edit = 'Pages.UniversalSettings.Connections.Edit';
    static readonly Pages_UniversalSettings_Connections_Delete = 'Pages.UniversalSettings.Connections.Delete';

    static readonly Pages_UniversalSettings_Settings = 'Pages.UniversalSettings.Settings';

    static readonly Pages_SystemHealth = 'Pages.SystemHealth';
    static readonly Pages_SystemHealth_SystemLog = 'Pages.SystemHealth.SystemLog';
    static readonly Pages_SystemHealth_SchedulerService = 'Pages.SystemHealth.SchedulerService';
    static readonly Pages_SystemHealth_WorkerService = 'Pages.SystemHealth.WorkerService';
    static readonly Pages_SystemHealth_LicenseService = 'Pages.SystemHealth.LicenseService';
    static readonly Pages_SystemHealth_AlarmerService = 'Pages.SystemHealth.AlarmerService';

    static readonly Pages_Projects = 'Pages.Projects';
    static readonly Pages_Projects_Create = 'Pages.Projects.Create';
    static readonly Pages_Projects_Edit = 'Pages.Projects.Edit';
    static readonly Pages_Projects_Delete = 'Pages.Projects.Delete';

    static readonly Pages_Projects_ReferencePoints = 'Pages.Projects.ReferencePoints';
    static readonly Pages_Projects_ReferencePoints_Create = 'Pages.Projects.ReferencePoints.Create';
    static readonly Pages_Projects_ReferencePoints_Edit = 'Pages.Projects.ReferencePoints.Edit';
    static readonly Pages_Projects_ReferencePoints_Delete = 'Pages.Projects.ReferencePoints.Delete';

    static readonly Pages_Projects_InstrumentPillars = 'Pages.Projects.InstrumentPillars';
    static readonly Pages_Projects_InstrumentPillars_Create = 'Pages.Projects.InstrumentPillars.Create';
    static readonly Pages_Projects_InstrumentPillars_Edit = 'Pages.Projects.InstrumentPillars.Edit';
    static readonly Pages_Projects_InstrumentPillars_Delete = 'Pages.Projects.InstrumentPillars.Delete';

    static readonly Pages_Projects_TargetPoints = 'Pages.Projects.TargetPoints';
    static readonly Pages_Projects_TargetPoints_Create = 'Pages.Projects.TargetPoints.Create';
    static readonly Pages_Projects_TargetPoints_Edit = 'Pages.Projects.TargetPoints.Edit';
    static readonly Pages_Projects_TargetPoints_Delete = 'Pages.Projects.TargetPoints.Delete';
    static readonly Pages_Projects_TargetPoints_ManualVerify = 'Pages.Projects.TargetPoints.ManualVerify';

    static readonly Pages_Projects_TpsSensors = 'Pages.Projects.TpsSensors';
    static readonly Pages_Projects_TpsSensors_Create = 'Pages.Projects.TpsSensors.Create';
    static readonly Pages_Projects_TpsSensors_Edit = 'Pages.Projects.TpsSensors.Edit';
    static readonly Pages_Projects_TpsSensors_Delete = 'Pages.Projects.TpsSensors.Delete';

    static readonly Pages_Projects_WeatherSensors = 'Pages.Projects.WeatherSensors';
    static readonly Pages_Projects_WeatherSensors_Create = 'Pages.Projects.WeatherSensors.Create';
    static readonly Pages_Projects_WeatherSensors_Edit = 'Pages.Projects.WeatherSensors.Edit';
    static readonly Pages_Projects_WeatherSensors_Delete = 'Pages.Projects.WeatherSensors.Delete';

    static readonly Pages_Projects_WeatherStations = 'Pages.Projects.WeatherStations';
    static readonly Pages_Projects_WeatherStations_Create = 'Pages.Projects.WeatherStations.Create';
    static readonly Pages_Projects_WeatherStations_Edit = 'Pages.Projects.WeatherStations.Edit';
    static readonly Pages_Projects_WeatherStations_Delete = 'Pages.Projects.WeatherStations.Delete';

    static readonly Pages_Projects_GnssSensors = 'Pages.Projects.GnssSensors';
    static readonly Pages_Projects_GnssSensors_Create = 'Pages.Projects.GnssSensors.Create';
    static readonly Pages_Projects_GnssSensors_Edit = 'Pages.Projects.GnssSensors.Edit';
    static readonly Pages_Projects_GnssSensors_Delete = 'Pages.Projects.GnssSensors.Delete';

    static readonly Pages_Projects_GnssStations = 'Pages.Projects.GnssStations';
    static readonly Pages_Projects_GnssStations_Create = 'Pages.Projects.GnssStations.Create';
    static readonly Pages_Projects_GnssStations_Edit = 'Pages.Projects.GnssStations.Edit';
    static readonly Pages_Projects_GnssStations_Delete = 'Pages.Projects.GnssStations.Delete';

    static readonly Pages_Projects_Stations = 'Pages.Projects.Stations';
    static readonly Pages_Projects_Stations_Create = 'Pages.Projects.Stations.Create';
    static readonly Pages_Projects_Stations_Edit = 'Pages.Projects.Stations.Edit';
    static readonly Pages_Projects_Stations_Delete = 'Pages.Projects.Stations.Delete';

    static readonly Pages_Projects_Groups = 'Pages.Projects.Groups';
    static readonly Pages_Projects_Groups_Create = 'Pages.Projects.Groups.Create';
    static readonly Pages_Projects_Groups_Edit = 'Pages.Projects.Groups.Edit';
    static readonly Pages_Projects_Groups_Delete = 'Pages.Projects.Groups.Delete';

    static readonly Pages_Licenses = 'Pages.Licenses';
    static readonly Pages_Licenses_Create = 'Pages.Licenses.Create';
    static readonly Pages_Licenses_Delete = 'Pages.Licenses.Delete';
    static readonly Pages_Licenses_RequestLicense = 'Pages.Licenses.RequestLicense';
    static readonly Pages_Licenses_ProjectLicense_Manage = 'Pages.Licenses.ProjectLicenseManage';

    static readonly Pages_Projects_Schedules = 'Pages.Projects.Schedules';
    static readonly Pages_Projects_Schedules_Create = 'Pages.Projects.Schedules.Create';
    static readonly Pages_Projects_Schedules_Edit = 'Pages.Projects.Schedules.Edit';
    static readonly Pages_Projects_Schedules_Delete = 'Pages.Projects.Schedules.Delete';

    static readonly Pages_Projects_WeatherSchedules = 'Pages.Projects.WeatherSchedules';
    static readonly Pages_Projects_WeatherSchedules_Create = 'Pages.Projects.WeatherSchedules.Create';
    static readonly Pages_Projects_WeatherSchedules_Edit = 'Pages.Projects.WeatherSchedules.Edit';
    static readonly Pages_Projects_WeatherSchedules_Delete = 'Pages.Projects.WeatherSchedules.Delete';

    static readonly Pages_Projects_GnssSchedules = 'Pages.Projects.GnssSchedules';
    static readonly Pages_Projects_GnssSchedules_Create = 'Pages.Projects.GnssSchedules.Create';
    static readonly Pages_Projects_GnssSchedules_Edit = 'Pages.Projects.GnssSchedules.Edit';
    static readonly Pages_Projects_GnssSchedules_Delete = 'Pages.Projects.GnssSchedules.Delete';

    static readonly Pages_Projects_ThresholdProfiles = 'Pages.Projects.ThresholdProfiles';
    static readonly Pages_Projects_ThresholdProfiles_Create = 'Pages.Projects.ThresholdProfiles.Create';
    static readonly Pages_Projects_ThresholdProfiles_Edit = 'Pages.Projects.ThresholdProfiles.Edit';
    static readonly Pages_Projects_ThresholdProfiles_Delete = 'Pages.Projects.ThresholdProfiles.Delete';

    static readonly Pages_Projects_Actions = 'Pages.Projects.Actions';
    static readonly Pages_Projects_Actions_Create = 'Pages.Projects.Actions.Create';
    static readonly Pages_Projects_Actions_Edit = 'Pages.Projects.Actions.Edit';
    static readonly Pages_Projects_Actions_Delete = 'Pages.Projects.Actions.Delete';

    static readonly Pages_Projects_MapView = 'Pages.Projects.MapView';

    static readonly Pages_Projects_WeatherDashboard = 'Pages.Projects.WeatherDashboard';

    static readonly Pages_SystemServices = 'Pages.SystemServices';
    static readonly Pages_SystemServices_Manage = 'Pages.SystemServices.Manage';

    static readonly Pages_Projects_AmasiaMaintenance = 'Pages.Projects.AmasiaMaintenance';
    static readonly Pages_Projects_AmasiaMaintenance_UnlockSensor = 'Pages.Projects.AmasiaMaintenance.UnlockSensor';
    static readonly Pages_Projects_AmasiaMaintenance_CompleteActivity = 'Pages.Projects.AmasiaMaintenance.CompleteActivity';
}
