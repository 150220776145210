<div class="btn-group dropdown" dropdown container="body">
    <button type="button" dropdownToggle class="dropdown-toggle btn btn-project-switch">
        <div class="loading-projects-spinner-container" *ngIf="isLoading">
            <i class="fa fa-spin fa-spinner loading-projects-spinner"></i>
        </div>
        {{ activeProjectName }}
    </button>
    <div *dropdownMenu>
        <ul class="dropdown-menu project-switch-dropdown">
            <li [class.active-project]="isActiveProject(project)" *ngFor="let project of projects">
                <span class="dropdown-item mr-3 project-link cursor-pointer" (click)="changeProject(project)">
                    {{ project.name }}
                    <button type="button" class="btn-logout-project" title="Return home" (click)="logoutProject($event)" *ngIf="isActiveProject(project)">
                        <span class="pi pi-home"></span>
                    </button>
                </span>
            </li>
        </ul>
    </div>
</div>