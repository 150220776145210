import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable()
export class DateTimeService {
    getDate(): moment.Moment {
        if (abp.clock.provider.supportsMultipleTimezone) {
            return moment().tz(abp.timing.timeZoneInfo.iana.timeZoneId);
        } else {
            return moment().local();
        }
    }

    getUTCDate(): moment.Moment {
        return moment().utc();
    }

    getYear(): number {
        return this.getDate().year();
    }

    getStartOfDay(): moment.Moment {
        return this.getDate().startOf('day');
    }

    getStartOfDayForDate(date: moment.Moment | Date): moment.Moment {
        if (!date) {
            return date as moment.Moment;
        }

        if (date instanceof Date) {
            return this.getStartOfDayForDate(this.fromJSDate(date));
        }

        return date.startOf('day');
    }

    getStartOfDayMinusDays(daysFromNow: number): moment.Moment {
        const date = this.getDate();
        const newDate = this.minusDays(date, daysFromNow);
        return this.getStartOfDayForDate(newDate);
    }

    getStartOfDayMinusMonths(monthsFromNow: number): moment.Moment {
        const date = this.getDate();
        const newDate = this.minusMonths(date, monthsFromNow);
        return this.getStartOfDayForDate(newDate);
    }

    getEndOfDay(): moment.Moment {
        return this.getDate().endOf('day');
    }

    getEndOfDayForDate(date: moment.Moment | Date): moment.Moment {
        if (!date) {
            return date as moment.Moment;
        }

        if (date instanceof Date) {
            return this.getEndOfDayForDate(this.fromJSDate(date));
        }

        return date.endOf('day');
    }

    getEndOfDayPlusDays(daysFromNow: number): moment.Moment {
        const date = this.getDate();
        const newDate = this.plusDays(date, daysFromNow);
        return this.getEndOfDayForDate(newDate);
    }

    getEndOfDayMinusDays(daysFromNow: number): moment.Moment {
        const date = this.getDate();
        const newDate = this.minusDays(date, daysFromNow);
        return this.getEndOfDayForDate(newDate);
    }

    plusDays(date: moment.Moment | Date, dayCount: number): moment.Moment {
        if (date instanceof Date) {
            return this.plusDays(this.fromJSDate(date), dayCount);
        }

        return date.add(dayCount, 'days');
    }

    plusSeconds(date: moment.Moment, seconds: number): moment.Moment {
        if (!date) {
            return date;
        }

        if (date instanceof Date) {
            return this.plusSeconds(this.fromJSDate(date), seconds);
        }

        return date.add(seconds, 'seconds');
    }

    minusDays(date: moment.Moment, dayCount: number): moment.Moment {
        return date.subtract(dayCount, 'days');
    }

    minusMonths(date: moment.Moment, monthsCount: number): moment.Moment {
        return date.subtract(monthsCount, 'months');
    }

    fromISODateString(date: string): moment.Moment {
        return moment(date);
    }

    formatISODateString(dateText: string, format: string): string {
        const date = this.fromISODateString(dateText);
        return date.format(format);
    }

    formatJSDate(jsDate: Date, format: string): string {
        const date = moment(jsDate);
        return date.format(format);
    }

    formatDate(date: moment.Moment | Date, format: string): string {
        if (date instanceof Date) {
            return this.formatDate(this.fromJSDate(date), format);
        }

        return date.format(format);
    }

    getDiffInSeconds(maxDate: moment.Moment | Date, minDate: moment.Moment | Date): number {
        if (maxDate instanceof Date && minDate instanceof Date) {
            return this.getDiffInSeconds(this.fromJSDate(maxDate), this.fromJSDate(minDate));
        }

        return (maxDate as moment.Moment).diff(minDate as moment.Moment, 'seconds');
    }

    createJSDate(year: number, month: number, day: number): Date {
        return this.createDate(year, month, day).toDate();
    }

    createDate(year: number, month: number, day: number): moment.Moment {
        if (abp.clock.provider.supportsMultipleTimezone) {
            return moment().utc().year(year).month(month).date(day);
        } else {
            return moment().local().year(year).month(month).date(day);
        }
    }

    createUtcDate(year: number, month: number, day: number): moment.Moment {
        return moment().utc().year(year).month(month).date(day);
    }

    toUtcDate(date: moment.Moment | Date): moment.Moment {
        if (date instanceof Date) {
            return this.createUtcDate(date.getFullYear(), date.getMonth(), date.getDate());
        }

        return this.createUtcDate(date.year(), date.month(), date.day());
    }

    fromJSDate(date: Date): moment.Moment {
        return moment(date);
    }

    fromNow(date: moment.Moment | Date): string {
        if (date instanceof Date) {
            return this.fromNow(this.fromJSDate(date));
        }

        return date.fromNow();
    }
}
