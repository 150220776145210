<div appBsModal #changePasswordModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"ChangePassword" | localize}}</span>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="CurrentPassword">{{"CurrentPassword" | localize}}</label>
                        <div class="position-relative">
                            <input id="CurrentPassword" #currentPasswordInput="ngModel" [type]="!isShowCurrentPassword ? 'password' : 'text'" name="CurrentPassword" class="form-control" [(ngModel)]="currentPassword" tabindex="1" autoFocus required>
                            <button type="button" class="password-toggle" (click)="toggleCurrentPasswordShow()">
                                <img *ngIf="!isShowCurrentPassword" alt="password-toggle" class="password-toggle-icon">
                                <img *ngIf="isShowCurrentPassword" alt="password-toggle-active" class="password-toggle-active-icon">
                            </button>
                        </div>
                        <validation-messages [formCtrl]="currentPasswordInput"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="NewPassword">{{"NewPassword" | localize}}</label>
                        <div class="position-relative">
                            <input id="NewPassword" [type]="!isShowNewPassword ? 'password' : 'text'" name="NewPassword" class="form-control" [(ngModel)]="password" #NewPassword="ngModel" validateEqual="NewPasswordRepeat"
                               reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                               [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                               tabindex="2" required>
                            <button type="button" class="password-toggle" (click)="toggleNewPasswordShow()">
                                <img *ngIf="!isShowNewPassword" alt="password-toggle" class="password-toggle-icon">
                                <img *ngIf="isShowNewPassword" alt="password-toggle-active" class="password-toggle-active-icon">
                            </button>
                        </div>
                        <validation-messages [formCtrl]="NewPassword"></validation-messages>
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPassword.errors">
                            <li [hidden]="!NewPassword.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                        </ul>
                    </div>
                    <div class="form-group">
                        <label for="NewPasswordRepeat">{{"NewPasswordRepeat" | localize}}</label>
                        <div class="position-relative">
                            <input id="NewPasswordRepeat" [type]="!isShowNewPasswordRepeat ? 'password' : 'text'" name="NewPasswordRepeat" class="form-control" [ngModel]="confirmPassword" #NewPasswordRepeat="ngModel" validateEqual="NewPassword"
                            [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                            [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                            reverse="false" tabindex="3" required>
                            <button type="button" class="password-toggle" (click)="toggleNewPasswordRepeatShow()">
                                <img *ngIf="!isShowNewPasswordRepeat" alt="password-toggle" class="password-toggle-icon">
                                <img *ngIf="isShowNewPasswordRepeat" alt="password-toggle-active" class="password-toggle-active-icon">
                            </button>
                        </div>
                        <validation-messages [formCtrl]="NewPasswordRepeat"></validation-messages>
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
                            <li [hidden]="!NewPasswordRepeat.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                            <li *ngIf="(NewPassword.touched && NewPasswordRepeat.touched) && (NewPassword.value !== NewPasswordRepeat.value)">{{"PasswordsDontMatch" | localize}}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!changePasswordModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
