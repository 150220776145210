import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-pangea-switch',
    templateUrl: './pangea-switch.component.html',
    styleUrls: ['./pangea-switch.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PangeaSwitchComponent),
            multi: true
        }
    ]
})
export class PangeaSwitchComponent implements ControlValueAccessor {
    value: boolean;
    isDisabled = false;

    private onChange: (value: boolean) => void;
    private onTouched: () => void;

    constructor() { }

    onInputValueChange(event: Event): void {
        const targetDivElement = event.target as HTMLInputElement;
        const value = targetDivElement.checked;

        this.onChange(value);
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
