<div appBsModal #profileSettingsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MyProfile" | localize}}</span>
                    </h5>
                </div>
                <div class="modal-body" *ngIf="user">
                    <h4>{{ user.name }} {{ user.surname }}</h4>

                    <div class="row mt-5">
                        <div class="col">
                            <div class="d-flex flex-column ">
                                <h3 class="profile-label">{{ 'UserName' | localize }}</h3>
                                <span>{{ user.userName }}</span>
                            </div>
        
                            <div class="d-flex flex-column mt-5">
                                <h3 class="profile-label">{{ 'EmailAddress' | localize }}</h3>
                                <span>{{ user.emailAddress }}</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="d-flex flex-column">
                                <h3 class="profile-label">{{ 'Roles' | localize }}</h3>
                                <span>{{ roles }}</span>
                            </div>
        
                            <div class="d-flex flex-column mt-5">
                                <h3 class="profile-label">{{ 'Projects' | localize }}</h3>
                                <span>{{ projects }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mt-5">
                        <label for="Name">{{"Name" | localize}} *</label>
                        <input id="Name" #nameInput="ngModel" class="form-control" type="text" name="Name" [(ngModel)]="user.name" required maxlength="64">
                        <validation-messages [formCtrl]="nameInput"></validation-messages>
                    </div>

                    <div class="form-group">
                        <label for="Surname">{{"Surname" | localize}} *</label>
                        <input id="Surname" #surnameInput="ngModel" type="text" name="Surname" class="form-control" [(ngModel)]="user.surname" required maxlength="64">
                        <validation-messages [formCtrl]="surnameInput"></validation-messages>
                    </div>

                    <!-- <div class="form-group">
                        <label for="PhoneNumber">{{"PhoneNumber" | localize}} *</label>
                        <input id="PhoneNumber" #PhoneNumberInput="ngModel" type="tel" name="PhoneNumber" class="form-control" [(ngModel)]="user.phoneNumber" required maxlength="256">
                        <validation-messages [formCtrl]="PhoneNumberInput"></validation-messages>
                    </div> -->

                    <div class="form-group d-flex flex-column">
                        <label for="phoneNumber">{{"PhoneNumber" | localize}} *</label>
                        <ngx-intl-tel-input
                            id="phoneNumber"
                            name="phoneNumber"
                            #phoneNumber="ngModel"
                            required
                            [maxLength]="15"
                            [selectedCountryISO]="countryISO.Australia"
                            [searchCountryFlag]="true"
                            [separateDialCode]="true"
                            [enableAutoCountrySelect]="true"
                            [(ngModel)]="user.phoneNumber">
                        </ngx-intl-tel-input>
                        <validation-messages [formCtrl]="phoneNumber"></validation-messages>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!mySettingsModalForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                        <i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
