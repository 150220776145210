import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trimLongText',
})
export class TrimLongTextPipe implements PipeTransform {
    transform(value: string, maxTextLength = 200): string {
        if (value && value.length > maxTextLength) {
            return value.substring(0, maxTextLength) + '...';
        }
        return value;
    }
}
