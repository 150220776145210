<div class="topbar-item">
    <a
        href="javascript:;"
        [class]="togglerCssClass"
        id="kt_quick_user_toggle"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title=""
        data-original-title="User Profile"
    >
        <span class="symbol symbol-30 symbol-lg-40">
            <span class="svg-icon svg-icon-xl">
                <img [src]="profilePicture" class="symbol-label"/>
            </span>
        </span>
    </a>
</div>

<div id="kt_quick_user" [perfectScrollbar]="{ wheelPropagation: true, suppressScrollX: true }"
     class="offcanvas offcanvas-right p-10 container d-flex flex-column min-vh-100">
    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5 content flex-grow-1">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
            <div class="symbol symbol-100 mr-5">
                <div class="symbol-label">
                    <img [src]="profilePicture" class="symbol-label"/>
                </div>
            </div>
            <div class="d-flex flex-column">
                <span *ngIf="isMultiTenancyEnabled"
                      class="font-weight-bold font-size-h5 mb-2 text-dark-75 topbar-username">{{ userName }} {{ userSurname }}</span>
                <span *ngIf="isMultiTenancyEnabled"
                      class="font-weight-bold font-size-h5 mb-2 text-dark-75 topbar-username">{{ userRoles }}</span>
                <div class="navi">
                    <button (click)="logout()" class="logout-button">
                        {{ "Logout" | localize }}
                    </button>
                </div>
            </div>
        </div>
        <!--end::Header-->

        <!--begin::Separator-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--end::Separator-->

        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
            <a href="javascript:;" *ngIf="isImpersonatedLogin" (click)="backToMyAccount()" class="navi-item"
               id="UserProfileBackToMyAccountButton">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-danger">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                >
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <path
                                            d="M21.4451171,17.7910156 C21.4451171,16.9707031 21.6208984,13.7333984 19.0671874,11.1650391 C17.3484374,9.43652344 14.7761718,9.13671875 11.6999999,9 L11.6999999,4.69307548 C11.6999999,4.27886191 11.3642135,3.94307548 10.9499999,3.94307548 C10.7636897,3.94307548 10.584049,4.01242035 10.4460626,4.13760526 L3.30599678,10.6152626 C2.99921905,10.8935795 2.976147,11.3678924 3.2544639,11.6746702 C3.26907199,11.6907721 3.28437331,11.7062312 3.30032452,11.7210037 L10.4403903,18.333467 C10.7442966,18.6149166 11.2188212,18.596712 11.5002708,18.2928057 C11.628669,18.1541628 11.6999999,17.9721616 11.6999999,17.7831961 L11.6999999,13.5 C13.6531249,13.5537109 15.0443703,13.6779456 16.3083984,14.0800781 C18.1284272,14.6590944 19.5349747,16.3018455 20.5280411,19.0083314 L20.5280247,19.0083374 C20.6363903,19.3036749 20.9175496,19.5 21.2321404,19.5 L21.4499999,19.5 C21.4499999,19.0068359 21.4451171,18.2255859 21.4451171,17.7910156 Z"
                                            fill="#000000"
                                            fill-rule="nonzero"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="navi-text">
                    <div class="font-weight-bold">
                        {{ "BackToMyAccount" | localize }}
                    </div>
                    <div class="text-muted">
                        {{ "BackToMyAccount_Description" | localize }}
                    </div>
                </div>
            </a>
            <a href="javascript:;" class="navi-item" id="UserProfileMySettingsLink" (click)="changeProfileSettings()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-dark">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                >
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <path
                                            d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                                            fill="#000000"
                                        />
                                        <path
                                            d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{ "MyProfile" | localize }}
                        </div>
                        <div class="text-muted">
                            {{ "MyProfile_Description" | localize }}
                        </div>
                    </div>
                </div>
            </a>

            <a href="javascript:;" class="navi-item" id="UserProfileChangePasswordLink" (click)="changePassword()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-success">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                >
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <circle fill="#F56513" cx="5" cy="12" r="2"/>
                                        <circle fill="#F56513" cx="12" cy="12" r="2"/>
                                        <circle fill="#F56513" cx="19" cy="12" r="2"/>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{ "ChangePassword" | localize }}
                        </div>
                        <div class="text-muted">
                            {{ "ChangePassword_Description" | localize }}
                        </div>
                    </div>
                </div>
            </a>
            <a href="javascript:;" class="navi-item" id="UserProfileChangePictureLink" (click)="changeProfilePicture()">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-info">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                >
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                        <rect fill="#000000" opacity="0.3" x="2" y="4" width="20" height="16" rx="2"/>
                                        <polygon fill="#000000" opacity="0.3" points="4 20 10.5 11 17 20"/>
                                        <polygon fill="#000000" points="11 20 15.5 14 20 20"/>
                                        <circle fill="#000000" opacity="0.3" cx="18.5" cy="8.5" r="1.5"/>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            {{ "ChangeProfilePicture" | localize }}
                        </div>
                        <div class="text-muted">
                            {{ "ChangeProfilePicture_Description" | localize }}
                        </div>
                    </div>
                </div>
            </a>
        </div>

    </div>
    <div class="font-weight-bold text-center">
        {{ "VersionNumber" | localize }} :  {{ versionNumber }}
    </div>
</div>
