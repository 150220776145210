import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[autoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
    constructor(private element: ElementRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.element.nativeElement.focus());
    }
}
