export class PointPrismConts {
    public static readonly EastingMin = -999999.999999;
    public static readonly EastingMax = 999999.999999;
    public static readonly NorthingMin = -9999999.999999;
    public static readonly NorthingMax = 9999999.999999;
    public static readonly HeightMin = -999999.999999;
    public static readonly HeightMax = 999999.999999;
    public static readonly PrismOffsetMinLength = -1;
    public static readonly PrismOffsetMaxLength = 1;
    public static readonly ReflectorHeightMinLength = -100;
    public static readonly ReflectorHeightMaxLength = 100;
    public static readonly InstrumentHeightMinLength = 0;
    public static readonly InstrumentHeightMaxLength = 100;
    public static readonly MinHaVa = 0;
    public static readonly MaxHaVa = 6.28318530718;
    public static readonly SearchWindowSizeMin = 0;//meters
    public static readonly SearchWindowSizeMax = 17.45329252;//meters
    public static readonly SearchWindowSizeDefault = 0.048481;//meters
    public static readonly SearchWindowReferenceDistance = 1000;//meters
}
