import { TokenService } from 'abp-ng2-module';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { concatMap, finalize, take } from 'rxjs/operators';
import { Component, Injector, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, PictureInput } from '@shared/service-proxies/service-proxies';
import { guid } from '@shared/utils/guid.util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbpHttpResponse } from '@shared/models/abp-http-response.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-change-profile-picture-modal',
    styleUrls: ['./change-profile-picture-modal.component.scss'],
    templateUrl: './change-profile-picture-modal.component.html',
})
export class ChangeProfilePictureModalComponent extends AppComponentBase {
    @ViewChild('modal', { static: true }) modal: ModalDirective;

    public saving = false;
    public maxPictureMbValue = 5;
    public isProfilePictureSet = false;
    public imageFile: File;
    public croppedImage: Blob;
    public profilePictureSrc: string;

    constructor(
        injector: Injector,
        private profileService: ProfileServiceProxy,
        private tokenService: TokenService,
        private httpClient: HttpClient,
    ) {
        super(injector);
    }

    public show(): void {
        this.showMainSpinner();
        this.resetModal();
        this.profileService
            .getProfilePicture()
            .pipe(
                take(1),
                finalize(() => this.hideMainSpinner()),
            )
            .subscribe((result) => {
                if (result && result.profilePicture) {
                    this.isProfilePictureSet = true;
                    this.profilePictureSrc = `data:image/jpeg;base64,${result.profilePicture}`;
                }
                this.modal.show();
            });
    }

    public close(): void {
        this.modal.hide();
    }

    public fileChanged(event: Event): void {
        const files: FileList = (event.target as HTMLInputElement).files;
        const file = files[0];
        if (this.isFileTypeValid(file)) {
            if (this.isFileSizeValid(file)) {
                this.imageFile = file;
                this.isProfilePictureSet = true;
            } else {
                this.message.warn(this.l('PictureWarnSizeLimit', this.maxPictureMbValue));
                this.resetModal();
            }
        } else {
            this.message.warn(this.l('PictureWarnFileType', 'jpg, jpeg, png'));
            this.resetModal();
        }
        (event.target as HTMLInputElement).value = '';
    }

    private isFileTypeValid(file: File): boolean {
        const fileExtensions = ['jpg', 'jpeg', 'png'];
        return fileExtensions.some((ext) => file.name.toLowerCase().endsWith(ext));
    }

    private isFileSizeValid(file: File): boolean {
        return file.size <= this.maxPictureMbValue * 1024 * 1024;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = base64ToFile(event.base64);
    }

    public save(): void {
        this.saving = true;
        this.uploadProfilePicture(this.croppedImage)
            .pipe(
                concatMap((resp) => this.updateProfilePicture(resp.result.fileToken)),
                take(1),
                finalize(() => (this.saving = false)),
            )
            .subscribe({
                next: () => {
                    abp.event.trigger('profilePictureChanged');
                    this.close();
                },
                error: (err) => {
                    this.message.error(err.error.message);
                },
            });
    }

    private uploadProfilePicture(image: Blob): Observable<
        AbpHttpResponse<{
            fileToken: string;
        }>
    > {
        const url = `${AppConsts.remoteServiceBaseUrl}/Profile/UploadProfilePicture`;
        const headers = new HttpHeaders();
        headers.append('Authorization', `Bearer ${this.tokenService.getToken()}`);
        const data = new FormData();
        data.append('FileType', image.type);
        data.append('FileName', 'ProfilePicture');
        data.append('FileToken', guid());
        data.append('file', image);
        return this.httpClient.post<AbpHttpResponse<{ fileToken: string }>>(url, data, { headers });
    }

    private updateProfilePicture(fileToken: string): Observable<void> {
        return this.profileService.updateProfilePicture(
            new PictureInput({
                fileToken,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            }),
        );
    }

    public clearProfileImage(): void {
        this.profileService
            .clearCurrentUserProfilePicture()
            .pipe(take(1))
            .subscribe(() => {
                abp.event.trigger('profilePictureChanged');
                this.close();
            });
    }

    private resetModal(): void {
        this.isProfilePictureSet = false;
        this.imageFile = null;
        this.croppedImage = null;
    }
}
