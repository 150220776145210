import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AmasiaDatabaseStatusService {
    private _isAmasiaDatabaseIncompatible = false;

    get isAmasiaDatabaseIncompatible(): boolean {
        return this._isAmasiaDatabaseIncompatible;
    }

    set isAmasiaDatabaseIncompatible(value: boolean) {
        this._isAmasiaDatabaseIncompatible = value;
    }
}
