import { AbpHttpConfigurationService, AbpHttpInterceptor, RefreshTokenService } from 'abp-ng2-module';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ZeroHttpConfigurationService } from './zero-http-configuration.service';
import { AmasiaDatabaseStatusInterceptorService } from './amasia-database-status-interceptor.service';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.LicenseServiceProxy,
        ApiServiceProxies.TargetPointsServiceProxy,
        ApiServiceProxies.TargetPointCandidatesServiceProxy,
        ApiServiceProxies.ReferencePointsServiceProxy,
        ApiServiceProxies.InstrumentPillarsServiceProxy,
        ApiServiceProxies.PangeaLogsServiceProxy,
        ApiServiceProxies.ProjectsServiceProxy,
        ApiServiceProxies.LdapServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.VersionServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.DatabasesServiceProxy,
        ApiServiceProxies.ProjectMembersServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.TpsSensorsServiceProxy,
        ApiServiceProxies.TpsStatusServiceProxy,
        ApiServiceProxies.SensorsServiceProxy,
        ApiServiceProxies.StationsServiceProxy,
        ApiServiceProxies.GroupsServiceProxy,
        ApiServiceProxies.SchedulesServiceProxy,
        ApiServiceProxies.SchedulesOverviewServiceProxy,
        ApiServiceProxies.ActivityServiceProxy,
        ApiServiceProxies.PointsServiceProxy,
        ApiServiceProxies.HistoryServiceProxy,
        ApiServiceProxies.EntityChangesServiceProxy,
        ApiServiceProxies.UltimaMaintenanceServiceProxy,
        ApiServiceProxies.ThresholdProfilesServiceProxy,
        ApiServiceProxies.ThresholdProfileChecksServiceProxy,
        ApiServiceProxies.ThresholdProfileAlarmNotificationsServiceProxy,
        ApiServiceProxies.ActionsServiceProxy,
        ApiServiceProxies.EntityTypesServiceProxy,
        ApiServiceProxies.MapServiceProxy,
        ApiServiceProxies.LayersServiceProxy,
        ApiServiceProxies.ViewServiceProxy,
        ApiServiceProxies.WeatherSensorsServiceProxy,
        ApiServiceProxies.WeatherStationsServiceProxy,
        ApiServiceProxies.WeatherSchedulesServiceProxy,
        ApiServiceProxies.GnssSensorsServiceProxy,
        ApiServiceProxies.GnssStationsServiceProxy,
        ApiServiceProxies.GnssActivityOverviewServiceProxy,
        ApiServiceProxies.DataServiceProxy,
        ApiServiceProxies.MapSettingsServiceProxy,
        ApiServiceProxies.WeatherDashboardsServiceProxy,
        ApiServiceProxies.WeatherDashboardWidgetsServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AmasiaDatabaseStatusInterceptorService, multi: true },
    ],
})
export class ServiceProxyModule {}
