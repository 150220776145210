import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LanguageProviderService {
    constructor() { }

    getCurrentLanguageName(): string {
        return 'en';
    }
}
