import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceEmptyValueWithText',
})
export class ReplaceEmptyValueWithTextPipe implements PipeTransform {
    transform(value: unknown, replaceText = '-'): unknown {
        if (typeof value === 'undefined' || value === null || (typeof value === 'string' && !value)) {
            return replaceText;
        }
        return value;
    }
}
