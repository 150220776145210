import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapLocaleMappingService } from 'assets/ngx-bootstrap/ngx-bootstrap-locale-mapping.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';
import { LanguageProviderService } from '@shared/services/language-provider.service';

export class NgxBootstrapDatePickerConfigService {

    private static _languageProvider: LanguageProviderService;

    private static setLanguageProviderService(languageProvider: LanguageProviderService):void
    {
        NgxBootstrapDatePickerConfigService._languageProvider = languageProvider;
    }

    private static getLanguageProviderService(): LanguageProviderService
    {
        return NgxBootstrapDatePickerConfigService._languageProvider;
    }

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getTheme(): string {
        return ThemeHelper.getTheme();
    }

    static getDatepickerLocale(): BsLocaleService {
        const localeService = new BsLocaleService();
        localeService.use(NgxBootstrapDatePickerConfigService.getLanguageProviderService().getCurrentLanguageName());
        return localeService;
    }

    static registerNgxBootstrapDatePickerLocales(languageProvider: LanguageProviderService): Promise<boolean> {
        NgxBootstrapDatePickerConfigService.setLanguageProviderService(languageProvider);
        var languageName = NgxBootstrapDatePickerConfigService.getLanguageProviderService().getCurrentLanguageName();
        if (languageName === 'en') {
            return Promise.resolve(true);
        }

        const supportedLocale = new NgxBootstrapLocaleMappingService().map(languageName).toLowerCase();
        const moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(languageName);

        return new Promise<boolean>((resolve, reject) => {
            import(`ngx-bootstrap/chronos/esm5/i18n/${supportedLocale}.js`)
                .then(module => {
                    defineLocale(languageName, module[`${moduleLocaleName}Locale`]);
                    resolve(true);
                }, reject);
        });
    }
}
