import { Directive, Input } from '@angular/core';
import { Table } from 'primeng/table';

@Directive({
    selector: '[pTableWithUnsort]',
})
export class PrimengTableWithUnsortDirective {
    @Input() defaultSortField = 'reset_sorting';
    @Input() defaultSortOrder = -1; // 1 = asc, -1 = desc

    constructor(private table: Table) {
        this.table.sort = (event: { originalEvent: PointerEvent; field: string }) => {
            if (this.table.sortMode === 'single') {
                let isDefaultSortOrder = false;
                if (this.table.sortField === event.field && this.table.sortOrder === -1) {
                    event.field = this.defaultSortField;
                    isDefaultSortOrder = true;
                }
                this.table._sortOrder = !isDefaultSortOrder
                    ? this.table.sortField === event.field
                        ? this.table.sortOrder * -1
                        : this.table.defaultSortOrder
                    : this.defaultSortOrder;
                this.table._sortField = event.field;
                this.table.sortSingle();
            }
            if (this.table.sortMode === 'multiple') {
                this.table.sortMultiple();
            }
        };
    }
}
