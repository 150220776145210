import { Pipe, PipeTransform } from '@angular/core';
import { UnitConverterService } from '../unit-converter.service';

@Pipe({
    name: 'angleConvert',
})
export class AngleConvertPipe implements PipeTransform {
    constructor(private ucs: UnitConverterService) {}

    transform(value: number): string | null {
        return this.ucs.convertAngleForView(value)?.toString();
    }
}
