import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/app-route.guard';
import { NgModule } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionsConsts } from '@shared/PermissionsConsts';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [{ path: '', redirectTo: '/app/projects', canLoad: [AppRouteGuard], pathMatch: 'full' }],
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule),
                        data: { preload: true, permission: PermissionsConsts.Pages_RolesAndUsers },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'projects',
                        loadChildren: () => import('app/projects/project.module').then((m) => m.ProjectModule),
                        data: { preload: true, permission: PermissionsConsts.Pages_Projects },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'welcome',
                        loadChildren: () => import('app/welcome/welcome.module').then((m) => m.WelcomeModule),
                    },
                    {
                        path: 'universal-settings',
                        loadChildren: () => import('@app/universal-settings/universal-settings.module').then((m) => m.UniversalSettingsModule),
                        data: { preload: true, permission: PermissionsConsts.Pages_UniversalSettings },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'license-manager',
                        loadChildren: () => import('@app/license-manager/license-manager.module').then((m) => m.LicenseManagerModule),
                        data: { preload: true, permission: PermissionsConsts.Pages_Licenses },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'system-health',
                        loadChildren: () => import('@app/system-health/system-health.module').then((m) => m.SystemHealthModule),
                        data: { preload: true, permission: PermissionsConsts.Pages_SystemHealth },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: '**',
                        redirectTo: 'projects',
                        canLoad: [AppRouteGuard],
                    },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                spinnerService.show();
            }

            if (event instanceof RouteConfigLoadEnd) {
                spinnerService.hide();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url]').setAttribute('content', window.location.href);
            }
        });
    }
}
