import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { RootModule } from './root/root.module';
import packageJson from './../package.json';
import './polyfills.ts';

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    return platformBrowserDynamic().bootstrapModule(RootModule);
};

const printAppVersion = (): void => {
    console.log(`%cAMASIA ${packageJson.version}`, ['background: #f56513', 'color: #fff', 'padding: 2px 6px', 'border-radius: 3px'].join(';'));
};

printAppVersion();
bootstrap();
