/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, OnChanges } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Directive({
    selector: '[tabOrder]'
})
export class TabOrderDirective implements OnChanges {
    @Input() tabOrder = 0;

    constructor(private tab: TabDirective) { }

    ngOnChanges(): void {
        (this.tab as any).tabOrder = +this.tabOrder;
        this.tab.tabset.tabs.sort((a: any, b: any) => a.tabOrder - b.tabOrder);
    } 
}