import { Component } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner name="main-spinner" bdColor="rgba(51,51,51,0.4)" size="medium" type="ball-clip-rotate" color="#DF5000">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner>
    `,
})
export class RootComponent {
    ngxSpinnerText: NgxSpinnerTextService;

    constructor(ngxSpinnerText: NgxSpinnerTextService) {
        this.ngxSpinnerText = ngxSpinnerText;
    }

    getSpinnerText(): string {
        return this.ngxSpinnerText.getText();
    }
}
