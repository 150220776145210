import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize, take } from 'rxjs/operators';
import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UserProfileEditDto } from '@shared/service-proxies/service-proxies';
import { cloneDeep, isObject } from 'lodash-es';
import { CountryISO } from 'ngx-intl-tel-input';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Component({
    selector: 'app-profile-settings-modal',
    templateUrl: './profile-settings-modal.component.html',
    styleUrls: ['./profile-settings-modal.component.scss'],
})
export class ProfileSettingsModalComponent extends AppComponentBase {
    @ViewChild('profileSettingsModal', { static: true }) modal: ModalDirective;
    @Output() modalSave = new EventEmitter<void>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public user: UserProfileEditDto;
    public canChangeUserName: boolean;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    countryISO = CountryISO;

    phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

    get roles(): string {
        return this.user.roles.join(', ') || '-';
    }

    get projects(): string {
        return this.user.projects.join(', ') || '-';
    }

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.user = result;
            const clonedUser = cloneDeep(this.user);
            if (clonedUser.phoneNumber) {
                const interval = setInterval(() => {
                    if (isObject(this.user.phoneNumber)) {
                        clearInterval(interval);
                        setTimeout(() => {
                            try {
                                const phoneNumber = this.phoneUtil.parse(clonedUser.phoneNumber);
                                this.user.phoneNumber = `${phoneNumber.getNationalNumber()}`;
                            } catch (error) {
                                console.log(error);
                            }
                        });
                    }
                });
            }
            this.modal.show();
        });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        const clonedUser = cloneDeep(this.user);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        clonedUser.phoneNumber = (clonedUser.phoneNumber as any).e164Number;
        this._profileService
            .updateCurrentUserProfile(clonedUser)
            .pipe(
                take(1),
                finalize(() => {
                    this.saving = false;
                }),
            )
            .subscribe(() => {
                this.appSession.user.name = this.user.name;
                this.appSession.user.surname = this.user.surname;
                this.appSession.user.emailAddress = this.user.emailAddress;
                this.message.success(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit();
                abp.event.trigger('app.currentUserInfoChanged');
            });
    }
}
