import { Injectable } from '@angular/core';
import { PangeaAsideService } from '@app/shared/layout/themes/pangea/pangea-aside.service';

@Injectable()
export class AppUiCustomizationService {
    constructor(private readonly pangeaAsideService: PangeaAsideService) {}

    public getAppModuleBodyClass(): string {
        let cssClass =
            'app-ui-customization.service.ts:91 page-fluid subheader-enabled aside-left-offcanvas header-fixed aside-fixed quick-panel-right demo-panel-right aside-left-enabled aside-enabled subheader-solid aside-fixed subheader-fixed';

        if (this.pangeaAsideService.isAssideMinimizedLsValue) {
            cssClass += ' aside-minimize';
        }

        return cssClass;
    }

    public getAccountModuleBodyClass(): string {
        return 'header-fixed header-mobile-fixed subheader-fixed subheader-enabled subheader-solid aside-enabled aside-fixed page-loading';
    }
}
