import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[integerOnly]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IntegerNumberDirective, multi: true }],
})
export class IntegerNumberDirective implements Validator {
    validate(control: AbstractControl): { [key: string]: unknown } {
        if (typeof control.value === 'undefined' || control.value === null) return null;
        return !Number.isInteger(control.value) ? { integerOnly: { invalid: true, actual: control.value } } : null;
    }
}
