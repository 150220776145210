import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/utils/services/local-storage.service';

const ASIDE_MINIMIZED_LS_KEY = 'AsideMinimized';

@Injectable()
export class PangeaAsideService {
    private _isAssideMinimizedLsValue: boolean;

    public get isAssideMinimizedLsValue(): boolean {
        return this._isAssideMinimizedLsValue;
    }

    public get isAssideMinimized(): boolean {
        return this.document.body.classList.contains('aside-minimize');
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private localStorageService: LocalStorageService
    ) {
        this.localStorageService.getItem(ASIDE_MINIMIZED_LS_KEY, (error: unknown, value: boolean) => {
            if (typeof value !== 'boolean') {
                this.localStorageService.setItem(ASIDE_MINIMIZED_LS_KEY, false);
            } else {
                this._isAssideMinimizedLsValue = value;
            }
        });
    }

    public toggle(): void {
        if (!this.isAssideMinimized) {
            this.minimizeAsside();
        } else {
            this.showAsside();
        }
    }

    private showAsside(): void {
        this.document.body.classList.remove('aside-minimize');
        this._isAssideMinimizedLsValue = false;
        this.localStorageService.setItem(ASIDE_MINIMIZED_LS_KEY, this.isAssideMinimizedLsValue);
    }

    private minimizeAsside(): void {
        this.document.body.classList.add('aside-minimize');
        this._isAssideMinimizedLsValue = true;
        this.localStorageService.setItem(ASIDE_MINIMIZED_LS_KEY, this.isAssideMinimizedLsValue);
    }
}