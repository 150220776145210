<div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div #kt_aside id="kt_aside" ktOffcanvas [options]="menuCanvasOptions" *ngIf="sideMenu?.items?.length" class="aside aside-left aside-fixed d-flex flex-column flex-row-auto">
            <app-pangea-brand></app-pangea-brand>
            <div id="kt_aside_menu_wrapper" class="aside-menu-wrapper flex-column-fluid">
                <ng-container *ngIf="sensorTypeDropdownAvailable">
                    <div class="sensor-type-dropdown-container">
                        <div class="form-group sensor-type-dropdown">
                            <label for="sensorType">{{ 'SensorType' | localize }}</label>
                            <div class="dropdown-container">
                                <select name="sensorType" id="sensorType" class="form-control" [formControl]="sensorTypeFormControl">
                                    <option [value]="sensorTypeEnum.Tps" *ngIf="[permissionsConsts.Pages_Projects_TpsSensors, permissionsConsts.Pages_Projects_Stations] | permissionAny">
                                        {{ resolveSensorTypeName(sensorTypeEnum.Tps) }}
                                    </option>
                                    <option [value]="sensorTypeEnum.Weather" *ngIf="[permissionsConsts.Pages_Projects_WeatherSensors, permissionsConsts.Pages_Projects_WeatherStations] | permissionAny">
                                        {{ resolveSensorTypeName(sensorTypeEnum.Weather) }}
                                    </option>
                                    <option [value]="sensorTypeEnum.Gnss" *ngIf="[permissionsConsts.Pages_Projects_GnssSensors, permissionsConsts.Pages_Projects_GnssStations] | permissionAny">
                                        {{ resolveSensorTypeName(sensorTypeEnum.Gnss) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="mx-4 my-4" style="border-bottom: 1px solid #F3F3F3"></div>
                    </div>
                </ng-container>

                <div id="kt_aside_menu" class="aside-menu">
                    <ul class="menu-nav return-to-home-nav py-0">
                        <li class="menu-item">
                            <a class="menu-link" routerLink="/app/projects">
                                <span class="menu-icon">
                                    <i class="pi pi-home"></i>
                                </span>
                                <span class="menu-text">
                                    {{ 'HomePage' | localize }}
                                </span>
                            </a>
                        </li>
                    </ul>
                    <ul class="menu-nav pt-0" style="overflow-y: auto; overflow-x: hidden;" [ngStyle]="asideMenuStyle">
                        <ng-container *ngIf="isShowAllMenuItems">
                            <li class="menu-item" *ngFor="let item of sideMenu?.items">
                                <a *ngIf="!item.items && (item.permissions?.length ? (item.permissions | permissionAny) : true)"
                                    [routerLink]="item.path" skipLocationChange (click)="interactMenuItem(item, $event)"
                                    class="menu-link" routerLinkActive="active" [title]="isAssideMinimized ? (item.title | localize) : ''">
                                    <span class="menu-item-here"></span>
                                    <span class="menu-icon" *ngIf="item.icon">
                                        <i class="menu-link-icon" [ngClass]="item.icon"></i>
                                    </span>
                                    <span class="menu-text">
                                        {{ item.title | localize }}
                                    </span>
                                </a>
                                <a *ngIf="item.items && (item.permissions?.length ? (item.permissions | permissionAny) : true)"
                                    [class.active-link]="checkIsActiveUrl(item)" [class.active]="checkIsActiveUrlWhenAsideMinimized(item)" (click)="interactMenuItem(item, $event)"
                                    class="menu-link" [ngClass]="menuDropdownClass" routerLinkActive="active" [title]="isAssideMinimized ? (item.title | localize) : ''">
                                    <span class="menu-item-here"></span>
                                    <span class="menu-icon" *ngIf="item.icon">
                                        <i class="menu-link-icon" [ngClass]="item.icon"></i>
                                    </span>
                                    <span class="menu-text">
                                        {{ item.title | localize }}
                                    </span>
                                </a>
                                <div *ngIf="showAsideSubMenu(item)">
                                    <ul class="menu-nav menu-nav-inner">
                                        <li class="menu-item" *ngFor="let innerItem of item.items">
                                            <a [routerLink]="innerItem.path"
                                                *ngIf="innerItem.permissions?.length ? (innerItem.permissions | permissionAny) : false"
                                                class="menu-link menu-link-inner" routerLinkActive="active">
                                                <span class="menu-item-here"></span>
                                                <span class="menu-icon" *ngIf="innerItem.icon">
                                                    <i class="menu-link-icon" [ngClass]="innerItem.icon"></i>
                                                </span>
                                                <span class="menu-text">
                                                    {{ innerItem.title | localize }}
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="!isShowAllMenuItems">
                            <li class="menu-item" *ngFor="let item of sideMenu?.items">
                                <a *ngIf="!item.items && (item.permissions?.length ? (item.permissions | permissionAny) : true)"
                                    [routerLink]="item.path" skipLocationChange (click)="interactMenuItem(item, $event)"
                                    class="menu-link" routerLinkActive="active" [title]="item.title | localize">
                                    <span class="menu-item-here"></span>
                                    <span class="menu-icon" *ngIf="item.icon">
                                        <i class="menu-link-icon" [ngClass]="item.icon"></i>
                                    </span>
                                    <span class="menu-text">
                                        {{ item.title | localize }}
                                    </span>
                                </a>
                                <ng-container *ngFor="let innerItem of item.items">
                                    <a *ngIf="innerItem.permissions?.length ? (innerItem.permissions | permissionAny) : false"
                                        [routerLink]="innerItem.path" class="menu-link" routerLinkActive="active" [title]="innerItem.title | localize">
                                        <span class="menu-item-here"></span>
                                        <span class="menu-icon" *ngIf="innerItem.icon">
                                            <i class="menu-link-icon" [ngClass]="innerItem.icon"></i>
                                        </span>
                                        <span class="menu-text">
                                            {{ innerItem.title | localize }}
                                        </span>
                                    </a>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div *ngIf="appSession.tenant && appSession.tenant.logoId" class="amasia-footer-brand">
                <img [src]="pangeaLogoUrl" alt="AMASIA Logo">
            </div>
        </div>
        <div class="d-flex flex-column flex-row-fluid content-wrapper" id="kt_wrapper" [ngStyle]="contentWrapperStyle">
            <div id="kt_header" class="header header-fixed" [ngStyle]="headerStyle">
                <div class="header-container d-flex align-items-stretch justify-content-between">
                    <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper"></div>
                    <div class="topbar">
                        <div class="d-flex align-items-center">
                            <app-pangea-brand *ngIf="!sideMenu"></app-pangea-brand>
                            <button type="button" class="btn menu-button burger-icon burger-icon-right" id="kt_aside_mobile_toggle" *ngIf="menuButtonAvailable">
                                <span></span>
                            </button>
                            <ul class="d-flex align-items-center menu-nav header-menu-items" *ngIf="topMenu?.items.length">
                                <li class="menu-item" [routerLink]="item.path" *ngFor="let item of topMenu?.items">
                                    <a *ngIf="item.permissions?.length ? (item.permissions | permissionAny) : true"
                                        [routerLink]="item.path" class="menu-link" routerLinkActive="active">
                                        <span class="menu-item-here"></span>
                                        <span class="menu-text">{{ item.title | localize }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="d-flex align-items-center">
                            <ng-container *ngIf="!isOutsideProject">
                                <app-header-projects-switch class="mr-4"></app-header-projects-switch>
                                <app-header-alarm-notifications class="mr-1"></app-header-alarm-notifications>
                            </ng-container>
                            <user-menu></user-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex: 1">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
