import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngleConvertPipe } from '@app/projects/unit-converter/pipes/angle-unit-converter.pipe';
import { CoordinatesConvertPipe } from '@app/projects/unit-converter/pipes/coordinates-unit-converter.pipe';
import { LengthConvertPipe } from '@app/projects/unit-converter/pipes/length-unit-converter.pipe';
import { DateToLocalDateStringPipe } from '@shared/common/pipes/date-to-local-date-string.pipe';
import { DateToLocalDateTimeStringPipe } from '@shared/common/pipes/date-to-local-date-time-string.pipe';
import { DateToLocalTimeStringPipe } from '@shared/common/pipes/date-to-local-time-string.pipe';
import { FeatureCheckerPipe } from '@shared/common/pipes/feature-checker.pipe';
import { LocalizePipe } from '@shared/common/pipes/localize.pipe';
import { FormatMessagePipe } from '@shared/common/pipes/format-message.pipe';
import { PermissionAllPipe } from '@shared/common/pipes/permission-all.pipe';
import { PermissionAnyPipe } from '@shared/common/pipes/permission-any.pipe';
import { PermissionPipe } from '@shared/common/pipes/permission.pipe';
import { ProjectDurationPipe } from '@shared/common/pipes/project-duration.pipe';
import { YesNoPipe } from '@shared/common/pipes/yes-no.pipe';
import { DecimalLimitPipe } from '@shared/common/pipes/decimal-limit.pipe';
import { MillivoltsToVoltagePipe } from '@shared/common/pipes/millivolts-to-voltage.pipe';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AngleConvertDirective } from './directives/angle-convert.directive';
import { ArrayToTreeConverterService } from './services/array-to-tree-converter.service';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { BusyIfDirective } from './directives/busy-if.directive';
import { ButtonBusyDirective } from './directives/button-busy.directive';
import { CoordinatesConvertDirective } from './directives/coordinates-convert.directive';
import { DynamicDropdownPositionDirective } from './directives/dynamic-dropdown-position.directive';
import { LengthConvertDirective } from './directives/length-convert.directive';
import { LocalStorageService } from './services/local-storage.service';
import { NullDefaultValueDirective } from './directives/null-value.directive';
import { NumberOnlyDirective } from './validation/number-only.directive';
import { ScriptLoaderService } from './services/script-loader.service';
import { StyleLoaderService } from './services/style-loader.service';
import { TreeDataHelperService } from './services/tree-data-helper.service';
import { ValidationMessagesComponent } from './validation-messages.component';
import { EqualValidator } from './validation/equal-validator.directive';
import { IntegerNumberDirective } from './validation/integer-number.directive';
import { PasswordComplexityValidator } from './validation/password-complexity-validator.directive';
import { PositiveNumberDirective } from './validation/positive-number-validator.directive';
import { TabOrderDirective } from './directives/tab-order.directive';
import { ReplaceEmptyValueWithTextPipe } from '@shared/common/pipes/replace-empty-value-with-text.pipe';
import { TemperatureConvertDirective } from './directives/temperature-convert.directive';
import { PressureConvertDirective } from './directives/pressure-convert.directive';
import { MaxTextLengthDirective } from './validation/max-length-validator.directive';
import { EmptyStringDirective } from './validation/empty-string.directive';
import { PrimengTreeNodeDirective } from './primeng/p-treenode.directive';
import { PrimengTableWithUnsortDirective } from './primeng/p-table-with-unsort.directive';
import { TrimLongTextPipe } from '@shared/common/pipes/trim-long-text.pipe';
import { TimeToStringPipe}  from '@shared/common/pipes/time-to-string.pipe';

@NgModule({
    imports: [CommonModule, TabsModule],
    providers: [
        LocalStorageService,
        ScriptLoaderService,
        StyleLoaderService,
        ArrayToTreeConverterService,
        TreeDataHelperService,
    ],
    declarations: [
        EqualValidator,
        PasswordComplexityValidator,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        LocalizePipe,
        PermissionPipe,
        PermissionAnyPipe,
        FeatureCheckerPipe,
        PermissionAllPipe,
        DateToLocalDateTimeStringPipe,
        DateToLocalTimeStringPipe,
        DateToLocalDateStringPipe,
        YesNoPipe,
        LengthConvertPipe,
        AngleConvertPipe,
        CoordinatesConvertPipe,
        DynamicDropdownPositionDirective,
        MaxTextLengthDirective,
        IntegerNumberDirective,
        LengthConvertDirective,
        AngleConvertDirective,
        TemperatureConvertDirective,
        PressureConvertDirective,
        CoordinatesConvertDirective,
        NumberOnlyDirective,
        ProjectDurationPipe,
        TabOrderDirective,
        PositiveNumberDirective,
        ReplaceEmptyValueWithTextPipe,
        EmptyStringDirective,
        PrimengTreeNodeDirective,
        PrimengTableWithUnsortDirective,
        TrimLongTextPipe,
        DecimalLimitPipe,
        MillivoltsToVoltagePipe,
        TimeToStringPipe
    ],
    exports: [
        EqualValidator,
        PasswordComplexityValidator,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        LocalizePipe,
        PermissionPipe,
        PermissionAnyPipe,
        FeatureCheckerPipe,
        PermissionAllPipe,
        DateToLocalDateTimeStringPipe,
        DateToLocalTimeStringPipe,
        DateToLocalDateStringPipe,
        YesNoPipe,
        LengthConvertPipe,
        AngleConvertPipe,
        CoordinatesConvertPipe,
        DynamicDropdownPositionDirective,
        MaxTextLengthDirective,
        IntegerNumberDirective,
        LengthConvertDirective,
        AngleConvertDirective,
        TemperatureConvertDirective,
        PressureConvertDirective,
        CoordinatesConvertDirective,
        NumberOnlyDirective,
        ProjectDurationPipe,
        TabOrderDirective,
        PositiveNumberDirective,
        ReplaceEmptyValueWithTextPipe,
        EmptyStringDirective,
        PrimengTreeNodeDirective,
        PrimengTableWithUnsortDirective,
        TrimLongTextPipe,
        DecimalLimitPipe,
        MillivoltsToVoltagePipe,
        TimeToStringPipe
    ]
})
export class UtilsModule {}
