import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { concat as _concat, filter as _filter, find as _find } from 'lodash-es';

class ErrorDef {
    error: string;
    localizationKey: string;
    errorProperty?: string;
}

@Component({
    selector: '<validation-messages>',
    template: `<div class="has-danger" *ngIf="formCtrl.invalid && formCtrl.touched">
        <div *ngFor="let errorDef of errorDefsInternal">
            <div *ngIf="getErrorDefinitionIsInValid(errorDef)" class="form-control-feedback">
                {{ getErrorDefinitionMessage(errorDef) }}
            </div>
        </div>
    </div>`,
    styles: [
        `
            .form-control-feedback {
                color: #fd397a;
                margin-top: 0.2rem;
                font-size: 0.85rem;
                text-align: left;
            }
        `,
    ],
})
export class ValidationMessagesComponent {
    _errorDefs: ErrorDef[] = [];

    @Input() formCtrl: AbstractControl;
    @Input() set errorDefs(value: ErrorDef[]) {
        this._errorDefs = value;
    }

    readonly standartErrorDefs: ErrorDef[] = [
        { error: 'required', localizationKey: 'ThisFieldIsRequired' },
        { error: 'minlength', localizationKey: 'PleaseEnterAtLeastNCharacter', errorProperty: 'requiredLength' },
        { error: 'maxlength', localizationKey: 'PleaseEnterNoMoreThanNCharacter', errorProperty: 'requiredLength' },
        { error: 'min', localizationKey: 'ValueShouldBeGreaterOrEqualTo', errorProperty: 'min' },
        { error: 'max', localizationKey: 'ValueShouldBeLessOrEqualTo', errorProperty: 'max' },
        { error: 'email', localizationKey: 'InvalidEmailAddress' },
        { error: 'pattern', localizationKey: 'InvalidPattern', errorProperty: 'requiredPattern' },
        { error: 'integerOnly', localizationKey: 'ValueShouldBeIntegerNumber', errorProperty: 'integerOnly' },
        { error: 'positiveOnly', localizationKey: 'ValueShouldBePositiveNumber', errorProperty: 'positiveOnly' },
        { error: 'invalidUrl', localizationKey: 'ResourceIsNotValid', errorProperty: 'invalidUrl' },
        { error: 'endHaLessStartHa', localizationKey: 'EndHaShouldBeGreaterThanStartHa' },
        { error: 'endVaLessStartVa', localizationKey: 'EndVaShouldBeGreaterThanStartVa' },
        { error: 'minPpmGreaterMaxPpm', localizationKey: 'MinPpmShouldBeLessThanMaxPpm' },
        { error: 'maxPpmLessMinPpm', localizationKey: 'MaxPpmShouldBeGreaterThanMinPpm' },
        { error: 'invalidDmsCoordinates', localizationKey: 'InvalidDmsCoordinates' },
        { error: 'invalidDdmCoordinates', localizationKey: 'InvalidDdmCoordinates' },
        { error: 'validatePhoneNumber', localizationKey: 'InvalidPhoneNUmber' },
        { error: 'whitespace', localizationKey: 'ValueShouldNotContainEmptySpaces' },
        { error: 'emptyString', localizationKey: 'ValueShouldNotContainOnlySpaces' },
        { error: 'invalidLicenseId', localizationKey: 'InvalidLicenseId' },
        { error: 'forbiddenSymbols', localizationKey: 'ValueShouldNotContainSymbols', errorProperty: 'symbols' },
        { error: 'invalidCombinationOfAbsoluteAndConditionValue', localizationKey: 'RuleConditionHasInvalidCombinationOfAbsoluteAndConditionValue' },
        { error: 'maxValueShouldBeGreaterThanMinValue', localizationKey: 'MaxValueShouldBeGreaterThanMinValue', errorProperty: 'min' },
        { error: 'minValueShouldBeLessThanMaxValue', localizationKey: 'MinValueShouldBeLessThanMaxValue', errorProperty: 'min' },
        { error: 'scheduleStartCannotBeInThePast', localizationKey: 'ScheduleStartCannotBeInThePast' },
        { error: 'invalidCombinationMeteoSourceTypeAndCorrectionModel', localizationKey: 'InvalidCombinationMeteoSourceTypeAndCorrectionModel' },
        { error: 'invalidProj4String', localizationKey: 'InvalidProj4String' },
        { error: 'invalidHexColor', localizationKey: 'InvalidHexColor' },
        { error: 'nameShouldBeUnique', localizationKey: 'NameShouldBeUnique' },
    ];

    get errorDefsInternal(): ErrorDef[] {
        const standarts = _filter(this.standartErrorDefs, (ed) => !_find(this._errorDefs, (edC) => edC.error === ed.error));
        return <ErrorDef[]>_concat(standarts, this._errorDefs);
    }

    constructor(private appLocalizationService: AppLocalizationService) {}

    getErrorDefinitionIsInValid(errorDef: ErrorDef): boolean {
        return !!this.formCtrl.errors[errorDef.error];
    }

    getErrorDefinitionMessage(errorDef: ErrorDef): string {
        let validatorValue = this.formCtrl.errors[errorDef.error][errorDef.errorProperty];
        if (validatorValue && typeof validatorValue === 'number') {
            if (validatorValue.toString().includes('e-')) {
                validatorValue = validatorValue.toFixed(+validatorValue.toString().split('-')[1]);
            }
        }
        return validatorValue || validatorValue === 0
            ? this.appLocalizationService.l(errorDef.localizationKey, validatorValue)
            : this.appLocalizationService.l(errorDef.localizationKey);
    }
}
