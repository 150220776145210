<span class="pangea-switch">
    <label>
        <input
            type="checkbox"
            name="pangea-switch"
            [disabled]="isDisabled"
            [(ngModel)]="value"
            (input)="onInputValueChange($event)">
        <span></span>
    </label>
</span>