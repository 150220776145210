import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Directive({
    selector: '[pTreeNode]',
})
export class PrimengTreeNodeDirective implements OnInit, OnDestroy {
    @Input() public pTreeNodeSelector = 'p-treenode';
    @Output() public pTreeNodeMouseEnter = new EventEmitter<void>();
    @Output() public pTreeNodeMouseLeave = new EventEmitter<void>();

    private destroy$ = new Subject<void>();

    constructor(private element: ElementRef) {}

    public ngOnInit(): void {
        const element = this.element.nativeElement.parentElement as HTMLElement;
        const pTreeNode = element.closest(this.pTreeNodeSelector);
        if (pTreeNode) {
            fromEvent(pTreeNode, 'mouseenter')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.pTreeNodeMouseEnter.emit();
                });

            fromEvent(pTreeNode, 'mouseleave')
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.pTreeNodeMouseLeave.emit();
                });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
