/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { filter as _filter, forEach as _forEach } from 'lodash-es';

@Injectable()
export class ArrayToTreeConverterService {

    createTree(array: any[], parentIdProperty: any, idProperty: any, parentIdValue: any, childrenProperty: string, fieldMappings: any): any {
        const tree = [];

        const nodes = _filter(array, [parentIdProperty, parentIdValue]);

        _forEach(nodes, node => {
            const newNode = {
                data: node
            };

            this.mapFields(node, newNode, fieldMappings);

            newNode[childrenProperty] = this.createTree(
                array,
                parentIdProperty,
                idProperty,
                node[idProperty],
                childrenProperty,
                fieldMappings
            );

            tree.push(newNode);
        });

        return tree;
    }

    mapFields(node: any, newNode: any, fieldMappings: any): void {
        _forEach(fieldMappings, fieldMapping => {
            if (!fieldMapping['target']) {
                return;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (fieldMapping.hasOwnProperty('value')) {
                newNode[fieldMapping['target']] = fieldMapping['value'];
            } else if (fieldMapping['source']) {
                newNode[fieldMapping['target']] = node[fieldMapping['source']];
            } else if (fieldMapping['targetFunction']) {
                newNode[fieldMapping['target']] = fieldMapping['targetFunction'](node);
            }
        });
    }
}
