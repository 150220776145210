import { PlatformLocation, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { HubCommonModule } from '@shared/common/common.module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { AppPreBootstrap } from '../AppPreBootstrap';
import { AppModule } from '../app/app.module';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { DomHelper } from '@shared/helpers/DomHelper';
import { CookieConsentService } from '@shared/common/session/cookie-consent.service';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { LocaleMappingService } from '@shared/services/locale-mapping.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import * as localForage from 'localforage';
import { LanguageProviderService } from '@shared/services/language-provider.service';

export function appInitializerFactory(injector: Injector,
    platformLocation: PlatformLocation,
    languageProvider: LanguageProviderService) {
    return (): Promise<boolean> => {
        const spinnerService = injector.get(NgxSpinnerService);

        spinnerService.show();

        return new Promise<boolean>((resolve, reject) => {
            AppConsts.appBaseHref = getBaseHref(platformLocation);
            const appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;

            initializeLocalForage();

            AppPreBootstrap.run(
                languageProvider,
                appBaseUrl,
                () => {
                    handleLogoutRequest(injector.get(AppAuthService));

                    const appSessionService: AppSessionService = injector.get(AppSessionService);
                    appSessionService.init().then(
                        () => {
                            initializeAppCssClasses(injector);
                            initializeTenantResources(injector);
                            initializeCookieConsent(injector);
                            registerLocales(languageProvider, resolve, reject, spinnerService);
                        },
                        (err) => {
                            spinnerService.hide();
                            reject(err);
                        },
                    );
                },
                resolve,
                reject,
            );
        });
    };
}

function initializeLocalForage() {
    localForage.config({
        driver: localForage.LOCALSTORAGE,
        name: 'Pangea AMASIA',
        version: 1.0,
        storeName: 'local_storage',
        description: 'Cached data for Pangea AMASIA',
    });
}

function initializeAppCssClasses(injector: Injector) {
    const appUiCustomizationService = injector.get(AppUiCustomizationService);

    //Css classes based on the layout
    if (abp.session.userId) {
        document.body.className = appUiCustomizationService.getAppModuleBodyClass();
    } else {
        document.body.className = appUiCustomizationService.getAccountModuleBodyClass();
    }
}

function initializeTenantResources(injector: Injector) {
    const appSessionService: AppSessionService = injector.get(AppSessionService);
    const metaImage = DomHelper.getElementByAttributeValue('meta', 'property', 'og:image');
    if (metaImage) {
        if (!appSessionService.tenant || !appSessionService.tenant.logoId) {
            return;
        } else {
            metaImage.setAttribute(
                'content',
                AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetLogo?tenantId=' + appSessionService.tenant.id,
            );
        }
    }
}

function initializeCookieConsent(injector: Injector) {
    const cookieConsentService: CookieConsentService = injector.get(CookieConsentService);
    cookieConsentService.init();
}

function getDocumentOrigin() {
    if (!document.location.origin) {
        return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }

    return document.location.origin;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function registerLocales(languageProvider: LanguageProviderService,
    resolve: (value?: boolean | Promise<boolean>) => void, reject: any, spinnerService: NgxSpinnerService) {
    if (shouldLoadLocale(languageProvider)) {
        const angularLocale = convertAbpLocaleToAngularLocale(languageProvider.getCurrentLanguageName());
        import(`/node_modules/@angular/common/locales/${angularLocale}.mjs`).then((module) => {
            registerLocaleData(module.default);
            NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales(languageProvider).then(() => {
                resolve(true);
                spinnerService.hide();
            });
        }, reject);
    } else {
        NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales(languageProvider).then(() => {
            resolve(true);
            spinnerService.hide();
        });
    }
}

export function shouldLoadLocale(languageProvider: LanguageProviderService): boolean {
    return languageProvider.getCurrentLanguageName() && languageProvider.getCurrentLanguageName() !== 'en-US';
}

export function convertAbpLocaleToAngularLocale(locale: string): string {
    return new LocaleMappingService().map('angular', locale);
}

export function getRemoteServiceBaseUrl(): string {
    return AppConsts.remoteServiceBaseUrl;
}

export function getCurrentLanguage(languageProvider: LanguageProviderService): string {
    return convertAbpLocaleToAngularLocale(languageProvider.getCurrentLanguageName());
}

export function getCurrencyCode(injector: Injector): string {
    const appSessionService: AppSessionService = injector.get(AppSessionService);
    return appSessionService.application.currency;
}

export function getBaseHref(platformLocation: PlatformLocation): string {
    const baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
        return baseUrl;
    }

    return '/';
}

function handleLogoutRequest(authService: AppAuthService): void {
    const currentUrl = UrlHelper.initialUrl;
    const returnUrl = UrlHelper.getReturnUrl();
    if (currentUrl.indexOf('account/logout') >= 0 && returnUrl) {
        authService.logout(true, returnUrl);
    }
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppModule,
        HubCommonModule.forRoot(),
        ServiceProxyModule,
        HttpClientModule,
        RootRoutingModule,
        NgxSpinnerModule,
    ],
    declarations: [RootComponent],
    providers: [
        { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [Injector, PlatformLocation, LanguageProviderService],
            multi: true
        },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage,
            deps: [LanguageProviderService]
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useFactory: getCurrencyCode,
            deps: [Injector],
        },
    ],
    bootstrap: [RootComponent],
})
export class RootModule {}
