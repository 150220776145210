import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToLocalTimeString',
})
export class DateToLocalTimeStringPipe implements PipeTransform {
    transform(value: moment.Moment): string {
        if (!value) return '';
        return new Date(value.valueOf()).toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
    }
}
